@tailwind utilities;

@layer utilities {
  .dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: black;
    animation: blink 1s infinite alternate;
  }

  .dot1 {
    animation-delay: 0s;
  }
  .dot2 {
    animation-delay: 0.25s;
  }
  .dot3 {
    animation-delay: 0.5s;
  }
  .dot4 {
    animation-delay: 0.75s;
  }

  @keyframes blink {
    to {
      background-color: #f5f5f5;
    }
  }

  .no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
