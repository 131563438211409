/* .circular-chart {
    max-width: 100%;
    max-height: 250px;
  } */
  
  /* .circle-bg {
    fill: none;
    stroke: #eeeeee;
    stroke-width: 4.9;
  }
  
  .circle {
    fill: none;
    stroke-width: 5;
    stroke: #0175ff;
    animation: progress 0.4s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  } */

  .circle-bg {
    fill: none;
    stroke: #3a3f58;
    stroke-width: 3.9; /* Thicker stroke width */
  }
  
  .circle {
    fill: none;
    stroke-width: 4; /* Thicker stroke width */
    stroke: #ffffff;
  }

  .animated{
    animation: progress 1s ease-out forwards;
  }
  
  .percentage {
    fill: #eee;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }