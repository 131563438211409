@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF Pro Text";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Light.ttf");
}

@font-face {
  font-family: "SF Pro Text";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Regular.ttf");
}

@font-face {
  font-family: "SF Pro Text";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Medium.ttf");
}

@font-face {
  font-family: "SF Pro Text";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-SemiBold.ttf");
}

@font-face {
  font-family: "SF Pro Text";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Bold.ttf");
}

@font-face {
  font-family: "SF Pro Text";
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Heavy.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-display: swap;
  font-style: normal;
  font-weight: 100;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Ultralight.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-display: swap;
  font-style: normal;
  font-weight: 200;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Thin.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Light.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Regular.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Medium.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-SemiBold.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Bold.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Black.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Heavy.ttf");
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-Regular.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-Italic.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: normal;
  font-weight: 100;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat[wght].woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: italic;
  font-weight: 100;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-Italic[wght].woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: normal;
  font-weight: 250;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-Thin.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: italic;
  font-weight: 250;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-ThinItalic.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: normal;
  font-weight: 275;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-ExtraLight.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: italic;
  font-weight: 275;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-ExtraLightItalic.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-Light.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-LightItalic.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-Medium.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-MediumItalic.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-SemiBold.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: italic;
  font-weight: 600;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-SemiBoldItalic.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-Bold.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-BoldItalic.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-ExtraBold.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: italic;
  font-weight: 800;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-ExtraBoldItalic.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-Black.woff) format('woff')
}

@font-face {
  font-family: montserrat;
  font-display: swap;
  font-style: italic;
  font-weight: 900;
  src: local('Montserrat'), url(https://fonts.cdnfonts.com/s/14883/Montserrat-BlackItalic.woff) format('woff')
}


/* cyrillic */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjHGEvjpFLlXs.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjHGEvhZFLlXs.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjHGEvhJFLlXs.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjHGEvipFL.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQ0bhXl3_qEpiwAKJBrs0M6tbA.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQ0bhXl3_qEpiwAKJBruEM6tbA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQ0bhXl3_qEpiwAKJBruUM6tbA.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQ0bhXl3_qEpiwAKJBrt0M6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjDGYvjpFLlXs.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjDGYvhZFLlXs.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjDGYvhJFLlXs.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Bellota';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjDGYvipFL.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAID55oGAfiIlP.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAID55oGsfiIlP.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAID55oGofiIlP.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAID55oGQfiA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQ2bhXl3_qEpiwAKJFbtVs-.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQ2bhXl3_qEpiwAKJpbtVs-.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQ2bhXl3_qEpiwAKJtbtVs-.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQ2bhXl3_qEpiwAKJVbtQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAIC5-oGAfiIlP.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAIC5-oGsfiIlP.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAIC5-oGofiIlP.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Bellota';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAIC5-oGQfiA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* vietnamese */
@font-face {
  font-family: 'Bricolage Grotesque';
  font-style: normal;
  font-weight: 200 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bricolagegrotesque/v7/3y9K6as8bTXq_nANBjzKo3IeZx8z6up5BeSl9D4dj_x9PpZBMlGHInHEVA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Bricolage Grotesque';
  font-style: normal;
  font-weight: 200 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bricolagegrotesque/v7/3y9K6as8bTXq_nANBjzKo3IeZx8z6up5BeSl9D4dj_x9PpZBMlGGInHEVA.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Bricolage Grotesque';
  font-style: normal;
  font-weight: 200 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bricolagegrotesque/v7/3y9K6as8bTXq_nANBjzKo3IeZx8z6up5BeSl9D4dj_x9PpZBMlGIInE.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Display', 'SFProText';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  src: local('Satoshi'), url('https://fonts.cdnfonts.com/s/85546/Satoshi-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: 400;
  src: local('Satoshi'), url('https://fonts.cdnfonts.com/s/85546/Satoshi-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 300;
  src: local('Satoshi'), url('https://fonts.cdnfonts.com/s/85546/Satoshi-Light.woff') format('woff');
}
@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: 300;
  src: local('Satoshi'), url('https://fonts.cdnfonts.com/s/85546/Satoshi-LightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 500;
  src: local('Satoshi'), url('https://fonts.cdnfonts.com/s/85546/Satoshi-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: 500;
  src: local('Satoshi'), url('https://fonts.cdnfonts.com/s/85546/Satoshi-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  src: local('Satoshi'), url('https://fonts.cdnfonts.com/s/85546/Satoshi-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: 700;
  src: local('Satoshi'), url('https://fonts.cdnfonts.com/s/85546/Satoshi-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  src: local('Satoshi'), url('https://fonts.cdnfonts.com/s/85546/Satoshi-Black.woff') format('woff');
}
@font-face {
  font-family: 'Satoshi';
  font-style: italic;
  font-weight: 900;
  src: local('Satoshi'), url('https://fonts.cdnfonts.com/s/85546/Satoshi-BlackItalic.woff') format('woff');
}


img {
  height: unset;
}

@layer base {
  html {
    font-family: 'SF Pro Text', system-ui;
  }
}

.bg-header{
  background: radial-gradient(circle, #FFD700, #FF4500);
}