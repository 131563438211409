@keyframes ProcessingAnimation {
    0% {
        transform: rotate(-90deg);
    }

    25% {
        transform: rotate(3deg);
    }

    50% {
        transform: rotate(88deg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(-90deg);
    }
}

.infiniteAnimated {
    animation: ProcessingAnimation 3s ease-in-out infinite;
}

@keyframes LoadingAnimation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loader {
    animation: LoadingAnimation 1.8s linear infinite;
}

.dots-size {
    transition: transform 0.5s linear 0s;
}

@keyframes animate-shake {
    0%,
    10%,
    65%,
    100% {
        transform: rotate(0deg) scale(1.0);
    }

    30%,
    40% {
        transform: rotate(-1deg) scale(1.05);
    }

    35%,
    45% {
        transform: rotate(1deg) scale(1.05);
    }
}

.shaking-animations {
    animation: animate-shake 2s ease-in-out infinite;
}


@keyframes animation-analyzing {
    10%{
        top: 15%;
        transform: translate(0%, 15%);
    }
    20%{
        transform: translate(0%, 60%);
    }
    35%{
        transform: translate(0%, 90%);
    }
    45%{
        transform: translate(0%, 100%);
    }
    46%{
        transform: translate(0%, 100%);
    }
    66%{
        transform:  translate(0%, 100%);
    }
    75%{
        transform: translate(0%, 90%);
    }
    90%{
        transform: translate(0%, 15%);
    }
    100%{
        transform: translate(0%, 0%);
    }
}


@keyframes animation-analyzing-2 {
    10% {
        top: 15%;
    }
    20% {
        top: 60%;
    }
    35% {
        top: 90%;
    }
    45% {
        top: 100%;
    }
    46% {
        top: 100%;
    }
    66% {
        top: 100%;
    }
    75% {
        top: 90%;
    }
    90% {
        top: 15%;
    }
    100% {
        top: 0%;
    }
}

.analyzing-animation{
    animation: animation-analyzing-2 8s ease-in-out infinite;
}

@keyframes border-lighting{

    50% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.animation-border{
    animation: border-lighting 1.5s ease-in-out infinite;
}