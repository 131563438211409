.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


body{
  background-color: white;
}

.remove_scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.remove_scroll::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus {
  box-shadow: none;
}


/* 
@media print {
  .result-page-block { page-break-before: always; }
} */